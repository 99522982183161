import React, { useState, useEffect, useContext, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import axios from 'axios';
import Pusher from 'pusher-js';
import Card from "../Daas/Portal/Projects/Card.js";
import KanBanSkeleton from "../Daas/Portal/Projects/KanBanSkeleton.js";
import localForage from "localforage";
import {UserContext} from "../Shared/UserContext.js";
import List from "../Daas/Portal/Projects/Task/List.js";
import {TaskboardContext} from "../Shared/TaskboardContext.js";
import MyCalendar from "../Calendar/MyCalendar.js";
import { useDraggable } from "react-use-draggable-scroll";
import NewLabel from "../Labels/NewLabel.js";
import { Modal } from 'react-responsive-modal';
import Loading from "../Shared/Loading.js";

const KanbanBoard = ({projectAuthorizations, fetchPath}) => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const currentUser = useContext(UserContext);
  const [columns, setColumns] = useState([]);
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [openTask, setOpenTask] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [list, setList] = useState(localStorage.getItem('list') || false);
  const [labels, setLabels] = useState([]);
  const [calendar, setCalendar] = useState(localStorage.getItem('calendar') || false);
  const [organizationUser, setOrganizationUser] = useState(projectAuthorizations.organization_user);
  const [openNewLabel, setOpenNewLabel] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [collapsedColumns, setCollapsedColumns] = useState({});

  const [filters, setFilters] = useState({
    hiddenColumns: [],
    users: [],
    sortBy: null,
    sortOrder: 'asc', // 'asc' or 'desc'
    hideEmptyColumns: false,
    projects: [],
    taskPriorities: [], 
    deadline: null
  });
  
  // const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
  // const { events } = useDraggable(ref); // Now we pass the reference to the useDraggable hook:

  // // Custom event handlers that will only trigger dragging on certain elements
  // const handleMouseDown = (e) => {
  //   if (e.target.classList.contains("draggable")) {
  //     events.onMouseDown(e);
  //   }
  // };

  // const handleTouchStart = (e) => {
  //   if (e.target.classList.contains("draggable")) {
  //     events.onTouchStart(e);
  //   }
  // };

  useEffect(() => {
    // fetchColumns();
    loadFilters();

    const controller = new AbortController();
    const signal = controller.signal;
    const fetchData = async () => {
      try {
        const value = await localForage.getItem(`${window.location.pathname}-2`);
        if (value) {
          setColumns(value.columns);
          if(value.projects != null){
            setProjects(value.projects);
          }
          if(value.users != null){
            setUsers(value.users);
          }
          setLoaded(true);
        }

        await fetchColumns(signal); // Fetch new data
      } catch (error) {
        console.error('Error fetching and updating data:', error);
      }
    };

    fetchData();

    return () => {
      // Cleanup function: cancel the ongoing fetch request
      controller.abort();
    };
  }, [location]); 
  
  // Update LocalForage when columns state changes
  useEffect(() => {
    localForage.getItem(`${window.location.pathname}-2`)
      .then(function (value) {
        if (value) {
          value.columns = columns;
          localForage.setItem(`${window.location.pathname}-2`, value)
            .then(function (updatedValue) {
              console.log('Columns updated in LocalForage:', updatedValue.columns);
            })
            .catch(function(err) {
              console.error('Error updating columns in LocalForage:', err);
            });
        }
      })
      .catch(function(err) {
        console.error('Error loading data from LocalForage:', err);
      });
  }, [columns]);

  useEffect(() => {
    saveFiltersToLocalForage();
  }, [filters]);

   // Update local storage whenever the list state changes
   useEffect(() => {
    if(list){
      localStorage.setItem('list', true);
    } else {
      localStorage.removeItem('list');
    }
  }, [list]);

  useEffect(() => {
    if(calendar){
      localStorage.setItem('calendar', true);
      localStorage.removeItem('list')
    } else {
      localStorage.removeItem('calendar');
    }
  }, [calendar]);

  useEffect(() => {
    const pusher = new Pusher('a5c19937d10212cddb4e', {cluster: 'us2'});

    const channel = pusher.subscribe(`dashboard-${match.params.organization_id}`);

    channel.bind('subscription', data => {
      console.log(data)
      // Handle the incoming event data here
      if(data.user_id != currentUser.id){
        // console.log('Received data:', data);
        fetchColumns();
      }
    });

    // Clean up the subscription when the component unmounts
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [match.params.organization_id]);

  const fetchColumns = async (signal) => {
    setFetchingData(true);
    try {
      const response = await axios.get(`/api/o/${match.params.organization_id}/${fetchPath}.json`, { signal });
      if (response.data.success) {
        setColumns(response.data.columns);
          setProjects(response.data.projects);
          setUsers(response.data.users);
          setLabels(response.data.labels);
          setLoaded(true);
          setFetchingData(false);
          localForage.setItem(`${window.location.pathname}-2`, response.data).then(function (value) {
            console.log(value);
          }).catch(function(err) {
            console.log(err);
          });

        await localForage.setItem(`${window.location.pathname}-2`, response.data);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        console.log(error);
      }
    }
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    // Return if dropped outside the droppable area or in the same position
    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      return;
    }

    const sourceColumnId = source.droppableId;
    const destinationColumnId = destination.droppableId;

    // Find the source and destination columns in the columns array
    const sourceColumnIndex = columns.findIndex(column => String(column.id) === sourceColumnId);
    const destinationColumnIndex = columns.findIndex(column => String(column.id) === destinationColumnId);

    const sourceColumn = columns[sourceColumnIndex];
    const destinationColumn = columns[destinationColumnIndex];

    // Move card within the same column
    if (sourceColumn === destinationColumn) {
      notice("Sorry, this feature isn't available, yet.")
    } else {
      // Create copies of the source and destination columns
      const updatedSourceColumn = { ...sourceColumn };
      const updatedDestinationColumn = { ...destinationColumn };

      // Find the index of the card being dragged in the source column
      const movedCardIndex = updatedSourceColumn.tasks.findIndex(card => card.id === draggableId);

      // Return if the card is not found in the source column
      if (movedCardIndex === -1) {
        // Handle error condition if card not found
        return;
      }

      // Remove the card from the source column and insert it into the destination column
      const [removed] = updatedSourceColumn.tasks.splice(movedCardIndex, 1);
      updatedDestinationColumn.tasks.splice(destination.index, 0, removed);

      // Update the card positions within the updated columns
      updatedSourceColumn.tasks = updatedSourceColumn.tasks.map((card, index) => ({
        ...card,
        position: index + 1,
      }));
      updatedDestinationColumn.tasks = updatedDestinationColumn.tasks.map((card, index) => ({
        ...card,
        position: index + 1,
      }));

      // Prepare the updated card data
      const updatedCard = removed;
      updatedCard.column_id = destinationColumn.id;

      // Update only the affected columns
      setColumns((prevColumns) => {
        const updatedColumns = [...prevColumns];
        updatedColumns[sourceColumnIndex] = updatedSourceColumn;
        updatedColumns[destinationColumnIndex] = updatedDestinationColumn;
        return updatedColumns;
      });

      console.log("updatedSourceColumn.tasks", updatedSourceColumn.title, updatedSourceColumn.tasks)
      console.log("updatedDestinationColumn.tasks",updatedDestinationColumn.title, updatedDestinationColumn.tasks);

      axios.post(`/api/portal/projects/${updatedCard.project.token}/tasks/update_positions`, {
        updates: updatedDestinationColumn.tasks,
        column_id: updatedDestinationColumn.token, 
        column_title: updatedDestinationColumn.title,
        dashboard_move: true,
        updated_card_id: updatedCard.token
      })
      .then(function (response) {
        // console.log(response);
        if(response.data.column.finished && response.data.review === null){
          setReviewForm({
            column: response.data.column,
            task: response.data.task
          })
        }
      })
      .catch(function (error) {
        console.log(error);
        // Handle the error as needed
      });
    }
  };
  
  

  const loadFilters = () => {
    localForage.getItem('kanban_filters')
      .then(value => {
        if (value) {
          setFilters(value);
        }
      })
      .catch(err => {
        console.error('Error loading filters from LocalForage:', err);
      });
  };

  const saveFiltersToLocalForage = () => {
    localForage.setItem('kanban_filters', filters)
      .then(() => console.log('Filters saved to localForage'))
      .catch(err => console.error('Error saving filters to LocalForage:', err));
  };

  const applyFilters = (columns) => {
    // Create a copy of the original columns array and filter out hidden columns
    // Columns
    const filteredColumns = columns.filter(column => !filters.hiddenColumns.some(hiddenColumn => hiddenColumn.title === column.title)).map(column => ({...column,tasks: [...column.tasks]}));

    
  
    if (filters.hideEmptyColumns) {
      // Filter out columns with no tasks
      filteredColumns = filteredColumns.filter(column => column.tasks.length > 0);
    }
    
    // Users
    if (filters.users.length > 0) {
      // Filter tasks within each column based on selected users
      filteredColumns.forEach(column => {
        column.tasks = column.tasks.filter(task => filters.users.some(user => task.users.some(taskUser => taskUser.id === user.id)));
      });
    }


    // Projects
    if (filters.projects.length > 0) {
      // Filter tasks within each column based on selected projects
      filteredColumns.forEach(column => {
        column.tasks = column.tasks.filter(task => filters.projects.some(project => project.id === task.project.id));
      });
    }
    
    // Date
    if (filters.sortBy === 'created_at') {
      // Sort tasks within each column based on created_at
      filteredColumns.forEach(column => {
        column.tasks.sort((a, b) => {
          const timestampA = new Date(a.created_at).getTime();
          const timestampB = new Date(b.created_at).getTime();
          return filters.sortOrder === 'asc' ? timestampA - timestampB : timestampB - timestampA;
        });
      });
    }

    // Priorities
    if (filters.taskPriorities.length > 0) {
      // Filter tasks within each column based on selected priorities
      filteredColumns.forEach(column => {
        column.tasks = column.tasks.filter(task => filters.taskPriorities.includes(task.priority));
      });
    }

    // Deadline
    if (filters.deadline === 'nearest' || filters.deadline === 'furthest') {
      // Sort tasks within each column based on deadline
      filteredColumns.forEach(column => {
        column.tasks.sort((a, b) => {
          const dateA = new Date(a.deadline);
          const dateB = new Date(b.deadline);
          const today = new Date();
    
          const diffA = Math.abs(today - dateA);
          const diffB = Math.abs(today - dateB);
    
          return filters.deadline === 'nearest' ? diffA - diffB : diffB - diffA;
        });
      });
    }
    
  
    return filteredColumns;
  };

  const filterByUserId = (userId, username, avatar) => {
    const updatedUsers = [...filters.users, { id: userId, username, avatar }];
    setFilters(prevFilters => ({
      ...prevFilters,
      users: updatedUsers
    }));
  };

  const filterByPriority = (priority) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      taskPriorities: [...prevFilters.taskPriorities, priority]
    }));
  };
  
  const filterByDeadline = (order) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      deadline: order
    }));
  };

  const reorderTasksByDate = (order) => {
    const today = new Date();
    const filteredColumnsCopy = JSON.parse(JSON.stringify(columns)); // Deep copy of columns
  
    filteredColumnsCopy.forEach(column => {
      column.tasks.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
  
        const diffA = Math.abs(today - dateA);
        const diffB = Math.abs(today - dateB);
  
        return order === 'nearest' ? diffA - diffB : diffB - diffA;
      });
    });
  
    setColumns(filteredColumnsCopy);
  };

  const filterByProjectId = (projectId, projectTitle) => {
    const updatedProjects = [...filters.projects, { id: projectId, title: projectTitle }];
    setFilters(prevFilters => ({
      ...prevFilters,
      projects: updatedProjects
    }));
  };

  const projects_list = projects.map(project => {
    return(
      <li key={project.id}>
        <a className="justify-content-between align-items-center" onClick={() => filterByProjectId(project.id, project.title)} style={{display: "flex"}}>
          {project.title}

          {filters.projects.some(item => item.id === project.id) &&
            <i className="fas fa-check text-right font-12 color-blue"></i>
          }
        </a>
      </li>
    )
  });

  const columns_list = columns.map(column => {
    return(
      <li key={column.id}>
        <a className="justify-content-between align-items-center" onClick={() => {
          const updatedColumns = [...filters.hiddenColumns, { title: column.title }];
          setFilters(prevFilters => ({
            ...prevFilters,
            hiddenColumns: updatedColumns
          }));
        }} style={{display: "flex"}}>
          {column.title}

          {filters.hiddenColumns.some(item => item.title === column.title) &&
            <i className="fas fa-check text-right font-12 color-blue"></i>
          }
        </a>
      </li>
    )
  });

  const users_list = users.map(user => {
    return (
      <li key={user.id}>
        <a onClick={() => filterByUserId(user.id, user.username, user.avatar)}>
          <img src={user.avatar} className="avatar-small mr-8" alt={user.username}/>
          {user.username}
  
          {filters.users.some(item => item.id === user.id) &&
            <i className="fas fa-check text-right font-12 color-blue"></i>
          }
        </a>
      </li>
    );
  });

  const filters_users_list = filters.users.map(user => {
    return(
      <div class="btn-group ml-8">
        <a className="btn btn-primary-outline ml-5 btn-small">
          <img src={user.avatar} className="filter-user-avatar"/>{user.username}
        </a>
        <a className="btn btn-primary-outline ml-5 btn-small color-red-hover" onClick={() => {
          setFilters(prevFilters => ({
            ...prevFilters,
            users: prevFilters.users.filter(item => item.id !== user.id)
          }));
        }}>
          <i className="fas fa-times"></i>
        </a>
      </div>
    )
  });

  const filters_projects_list = filters.projects.map(project => {
    return(
      <div class="btn-group ml-8">
        <a className="btn btn-primary-outline ml-5 btn-small">
          <i className="fal fa-layer-group mr-5"></i>{project.title}
        </a>
        <a className="btn btn-primary-outline ml-5 btn-small color-red-hover" onClick={() => {
          setFilters(prevFilters => ({
            ...prevFilters,
            projects: prevFilters.projects.filter(p => p.id !== project.id)
          }));
        }}>
          <i className="fas fa-times"></i>
        </a>
      </div>
    )
  });

  const filters_hidden_columns_list = filters.hiddenColumns.map(hiddenColumn => {
    return(
      <div class="btn-group ml-8" key={hiddenColumn.title}>
        <a className="btn btn-primary-outline ml-5 btn-small">
          <i className="fal fa-columns mr-5"></i>{hiddenColumn.title}
        </a>
        <a className="btn btn-primary-outline ml-5 btn-small color-red-hover" onClick={() => {
          setFilters(prevFilters => ({
            ...prevFilters,
            hiddenColumns: prevFilters.hiddenColumns.filter(item => item.title !== hiddenColumn.title)
          }));
        }}>
          <i className="fas fa-times"></i>
        </a>
      </div>
    )
  });

  const filters_task_priorities = filters.taskPriorities.map(taskPrioritie => {
    return(
      <div class="btn-group ml-8">
        <a className="btn btn-primary-outline ml-5 btn-small">
          <i className="fal fa-exclamation-circle mr-5"></i>{taskPrioritie}
        </a>
        <a className="btn btn-primary-outline ml-5 btn-small color-red-hover" onClick={() => {
          setFilters(prevFilters => ({
            ...prevFilters,
            taskPriorities: prevFilters.taskPriorities.filter(item => item !== taskPrioritie)
          }));
        }}>
          <i className="fas fa-times"></i>
        </a>
      </div>
    )
  });

  const toggleColumnCollapse = (columnId) => {
    setCollapsedColumns(prev => ({
      ...prev,
      [columnId]: !prev[columnId]
    }));
  };


  return (
    <TaskboardContext.Provider value={{labels, setLabels, columns, setColumns, projectAuthorizations, columns, setColumns, organizationUser, setOpenNewLabel, users}}>
      <title>Requests</title>
      <Loading loading={fetchingData} />
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="container-fluid no-padding" style={{overflow: "auto"}}>
          <div className="display-flex justify-content-between align-items-center mb-15" style={{marginLeft: "5px"}}>
            <div className="display-flex justify-content-start align-items-center mt-10">
              <div class="dropdown">
                {!calendar && 
                  <a className="dropdown-toggle btn-small btn-primary-outline border-radius" varient="" data-toggle="dropdown">
                    <i class="fal fa-filter"></i> Filters
                  </a>
                }

                <ul class="dropdown-menu animated fadeInUp" style={{minWidth: "unset"}}>
                  {/* Deadline */}
                  <li class="dropdown-submenu">
                    <a class="test" tabindex="-1">
                      <i class="fal fa-calendar-exclamation"></i> Deadline
                    </a>

                    <ul class="dropdown-menu">
                      <li>
                        <a onClick={() => filterByDeadline('nearest')}>
                          <i class="fal fa-calendar-exclamation color-red"></i>Approaching
                        </a>
                      </li>

                      <li>
                        <a onClick={() => filterByDeadline('furthest')}>
                          <i class="fal fa-calendar-exclamation color-green"></i>Furthest
                        </a>
                      </li>
                    </ul>
                  </li>

                  {/* Users */}
                  <li class="dropdown-submenu">
                    <a class="test" tabindex="-1">
                      <i className="fal fa-users"></i> Assignee
                    </a>
                    <ul class="dropdown-menu" style={{ maxHeight: 250, overflow: "auto", background: "white" }}>
                      {users_list}
                    </ul>
                  </li>

                  {/* Priorities */}
                  <li class="dropdown-submenu">
                    <a class="test" tabindex="-1">
                      <i className="fal fa-exclamation-circle"></i> Priority
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <a onClick={() => filterByPriority('High')}>
                          <i class="fas fa-circle color-red"></i>High
                        </a>
                      </li>
                      <li>
                        <a onClick={() => filterByPriority('Medium')}>
                          <i class="fas fa-circle color-yellow"></i>Medium
                        </a>
                      </li>
                      <li>
                        <a onClick={() => filterByPriority('Low')}>
                          <i class="fas fa-circle color-green"></i>Low
                        </a>
                      </li>
                    </ul>
                  </li>

                  {/* Projects */}
                  <li class="dropdown-submenu">
                    <a class="test" tabindex="-1">
                      <i className="fal fa-layer-group"></i> Project
                    </a>

                    <ul class="dropdown-menu" style={{maxHeight: 250, overflow: "auto", background: "white"}}>
                      {projects_list}
                    </ul>
                  </li>

                  {/* Columns */}
                  <li class="dropdown-submenu">
                    <a class="test" tabindex="-1">
                      <i className="fal fa-columns"></i> Hide Columns
                    </a>
                    <ul class="dropdown-menu" style={{ maxHeight: 250, overflow: "auto", background: "white" }}>
                      {columns_list}
                    </ul>
                  </li>

                  {/* Date */}
                  <li class="dropdown-submenu">
                    <a class="test" tabindex="-1">
                      <i className="fal fa-calendar-plus"></i> Created at
                    </a>

                    <ul class="dropdown-menu">
                      <li>
                        <a onClick={() => reorderTasksByDate('nearest')}>
                          <i class="fal fa-calendar-plus color-red"></i>Nearest
                        </a>
                      </li>

                      <li>
                        <a onClick={() => reorderTasksByDate('furthest')}>
                          <i class="fal fa-calendar-plus color-green"></i>Furthest
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              {filters_projects_list}
              {filters_hidden_columns_list}
              {filters_task_priorities}
              {filters_users_list}

              {filters.user_id != null && 
                <div class="btn-group ml-8">
                  <a className="btn btn-primary-outline ml-5 btn-small">My tasks</a>
                  <a className="btn btn-primary-outline ml-5 btn-small color-red-hover" onClick={() => {
                    setFilters(prevFilters => ({
                      ...prevFilters,
                      user_id: null
                    }));
                  }}>
                    <i className="fas fa-times"></i>
                  </a>
                </div>
              }

              {filters.deadline != null && 
                <div class="btn-group ml-8">
                  <a className="btn btn-primary-outline ml-5 btn-small"><i class="fal fa-calendar-exclamation mr-5"></i>Deadline {filters.deadline}</a>
                  <a className="btn btn-primary-outline ml-5 btn-small color-red-hover" onClick={() => filterByDeadline(null)}>
                    <i className="fas fa-times"></i>
                  </a>
                </div>
              }
            </div>

            <div>
              <a className={`btn btn-small background-hover mr-15 color-1 ${calendar ? "background-active" : ""}`} onClick={() => {setCalendar(!calendar); setList(false)}}>
                <i class="fas fa-calendar-alt"></i>
              </a>
              <a className={`btn btn-small background-hover mr-15 color-1 ${(list || calendar) ? "" : "background-active"}`} onClick={() => {setList(false); setCalendar(false)}}>
                <i class="fas fa-th"></i>
              </a>
              <a className={`btn btn-small background-hover color-1 ${list ? "background-active" : ""}`} onClick={() => {setList(true); setCalendar(false)}}>
                <i class="far fa-bars"></i>
              </a>
            </div>
          </div>
          
          {(loaded && calendar) && 
            <div className="animated fadeIn">
              <MyCalendar organization_id={match.params.organization_id} projectAuthorizations={projectAuthorizations} project_id={null} height="calc(100vh - 170px)"/>
            </div>
          }

          {!calendar && 
            <div className={`row animated fadeIn ${list ? "taskboard-list border-all" : "pl-0 list-wrapper-dashboard show-scrollbar"}`} style={{ overflow: "auto" }}>
              {(loaded) ? (
                <div className={`${list ? "": "row draggable display-flex flex-wrap-nowrap user-select-none"}`}>
                  {(columns.length === 0 || columns.every(column => column.tasks.length === 0)) ?(
                    <div className="display-flex align-items-center justify-content-center flex-direction-column height-70vh">
                      <h4>No task has been created</h4>
                      <p className="opacity-6">Here you'll find all of the tasks from your projects in one place.</p>
                      <p className="opacity-6">
                        <a href="https://guides.usequeue.com" target="_blank" className="btn btn-small background-hover color-1"><i class="fal fa-play-circle mr-8"></i>Video guides</a>
                      </p>
                    </div>
                  ):(
                    <>
                      {applyFilters(columns).filter(column => column.tasks.length > 0).map((column, index) => (
                        <Droppable key={String(column.id)} droppableId={String(column.id)}>
                          {(provided) => (
                            <>
                              {list ? (
                                <div ref={provided.innerRef} {...provided.droppableProps} className="list-column">
                                  <div className="taskboard-column-lists">
                                    <div className="list-header mb-15">
                                      <h5 className="kanban-list-header background-3 default-padding mt-0 mb-0 display-flex justify-content-between align-items-center">
                                        {column.title}
                                        <span className="ml-10 font-12 opacity-6 badge background-active">
                                          {column.tasks.length}
                                        </span>

                                        <div>
                                          <i 
                                            className={`fal fa-angle-${collapsedColumns[column.id] ? 'right' : 'down'} btn btn-small background-hover`}
                                            onClick={() => toggleColumnCollapse(column.id)}
                                          ></i>
                                        </div>
                                      </h5>
                                    </div>
                                    {!collapsedColumns[column.id] && (
                                      <>
                                        {column.tasks.sort((a, b) => a.position - b.position).map((card, index) => (
                                          <List
                                            key={card.token}
                                            card={card}
                                            index={index}
                                            refetchData={fetchColumns}
                                            organizationUser={true}
                                            column={column}
                                            draggable_id={card.id}
                                            columns={card.columns}
                                            openTask={openTask === card.token}
                                            setOpenTask={setOpenTask}
                                            projectAuthorizations={projectAuthorizations}
                                            project_id={card.project.token}
                                            users={card.users_as_strings}
                                            project_title={card.project.title}
                                            avatar={card.avatar}
                                          />
                                        ))}
                                        {provided.placeholder}
                                      </>
                                    )}
                                  </div>
                                </div>
                              ):(
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  className="kanban-column draggable"
                                  style={{cursor: "default"}}
                                >
                                  <div className="card">
                                    <div className="card-header">
                                      <h5 className="kanban-card-header" style={{justifyContent: "unset"}}>
                                        {column.title}
                                        <span className="ml-5 font-12 opacity-6 badge">
                                          {column.tasks.length}
                                        </span>
                                      </h5>
                                    </div>
                                    <div className="card-body">
                                      {column.tasks.sort((a, b) => a.position - b.position).map((card, index) => (
                                        <div className="">
                                          <Card
                                            key={card.token}
                                            card={card}
                                            index={index}
                                            refetchData={fetchColumns}
                                            organizationUser={true}
                                            column={column}
                                            draggable_id={card.id}
                                            columns={card.columns}
                                            openTask={openTask === card.token}
                                            setOpenTask={setOpenTask}
                                            projectAuthorizations={projectAuthorizations}
                                            project_id={card.project.token}
                                          />
                                        </div>
                                      ))}
                                      {provided.placeholder}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </Droppable>
                      ))}
                    </>
                  )}
                </div>
              ):(
                <div className="ml-15-negative mr-15-negative">
                  <KanBanSkeleton />
                </div>
              )}
            </div>
          }
        </div>
      </DragDropContext>

      <Modal open={openNewLabel} onClose={() => setOpenNewLabel(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <NewLabel closeModal={() => setOpenNewLabel(false)}/>
      </Modal>
    </TaskboardContext.Provider>
  );
};

export default KanbanBoard;