import React from 'react';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
const StageIcon = ({stage, size}) => {
  return(
    <React.Fragment>
      <i className="mr-15 display-flex align-items-center">
        {stage === "in_queue" && 
          <svg width={size || "15"} height={size || "15"} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="25" cy="25" r="22.5" stroke="#87909E" stroke-width="5"/>
          </svg>
        }

        {stage === "in_progress" && 
          <svg width={size || "15"} height={size || "15"} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.0001 49.9999C31.6305 49.9999 37.9894 47.366 42.6778 42.6775C47.3662 37.9891 50.0001 31.6303 50.0001 24.9999C50.0001 18.3695 47.3662 12.0106 42.6778 7.32221C37.9894 2.6338 31.6305 -0.000121069 25.0001 -0.00012207L25.0001 24.9999L25.0001 49.9999Z" fill="#F5C402"/>
          <circle cx="25" cy="25" r="22.5" stroke="#F5C402" stroke-width="5"/>
          </svg>        
        }

        {stage === "in_review" && 
          <svg width={size || "15"} height={size || "15"} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.00012207 24.9999C0.00012207 29.9444 1.46635 34.7779 4.21338 38.8891C6.96042 43.0004 10.8649 46.2047 15.433 48.0969C20.0012 49.9891 25.0279 50.4841 29.8774 49.5195C34.7269 48.5549 39.1815 46.1739 42.6778 42.6775C46.1741 39.1812 48.5551 34.7267 49.5198 29.8771C50.4844 25.0276 49.9893 20.0009 48.0971 15.4328C46.2049 10.8646 43.0006 6.96017 38.8894 4.21314C34.7781 1.4661 29.9447 -0.000122129 25.0001 -0.00012207L25.0001 24.9999L0.00012207 24.9999Z" fill="#FFAB58"/>
          <circle cx="25" cy="25" r="22.5" stroke="#FFAB58" stroke-width="5"/>
          </svg>
          
        }

        {stage === "done" && 
          <svg width={size || "15"} height={size || "15"} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25ZM22.4998 33.5355L36.0353 20L32.4998 16.4645L22.4998 26.4645L17.4998 21.4645L13.9642 25L22.4998 33.5355Z" fill="#00C292"/>
          </svg>
        }
      </i>
    </React.Fragment>
  )
}

export default StageIcon;