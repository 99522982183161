import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import TaskForms from "./TaskForms.js";
import { Modal } from 'react-responsive-modal';
import Stripe from "../Stripe/Stripe.js";
import VideoGuide from "../../Shared/VideoGuide.js";
import TaskForm from "./TaskForm.js";
import Customize from "./Customize.js";
import AiTaskForms from "./AiTaskForms.js";

const Index = ({}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [services, setServices] = useState([]);
  const [serviceForms, setServiceForms] = useState([])
  const [stripeAccount, setStripeAccount] = useState(null);
  const [openCustomize, setOpenCustomize] = useState(false);
  const [openAiTaskForms, setOpenAiTaskForms] = useState(false);

  useEffect(() => {
    fetchServiceForms();
  }, []);
  
  const fetchServiceForms = () => {
    axios.get(`/api/o/${match.params.organization_id}/service_forms.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setServiceForms(response.data.service_forms); 
        setStripeAccount(response.data.stripe_account);
        setLoaded(true);
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // setLoaded(true)
    });
  }

  const service_forms_list = serviceForms.map(service_form => {
    return (
      <TaskForm service_form={service_form} services={services} refetchData={fetchServiceForms}/>
    )
  })

  return(
    loaded ? (
      <React.Fragment>
        <title>Task Forms</title>
        <div className="container mt-25">
          <div className="row">
            <div className="header-page">
              <div>
                <h4 className="font-weight-600 no-margin">Task forms</h4>
                <p className="font-12 opacity-7">Add custom questions to new tasks requests</p>
              </div>
          
              <div>
                <a onClick={() => setOpen(true)} className="btn btn-primary">
                  <i className="fas fa-plus mr-8"></i>Add task form
                </a>

                {/* <a onClick={() => setOpenAiTaskForms(true)} className="btn btn-primary">
                  <i className="fas fa-plus mr-8"></i>Add task form
                </a>

                <Modal open={openAiTaskForms} onClose={() => setOpenAiTaskForms(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center> 
                  <AiTaskForms refetchData={fetchServiceForms} closeModal={() => setOpenAiTaskForms(false)}/>
                </Modal> */}

                {/* <div class="btn-group">
                  <a type="button" class="ml-15 color-1 dropdown-toggle mr-15 btn-icon opacity-6 opacity-10-hover" data-toggle="dropdown">
                    <i class="fas fa-ellipsis-v"></i>
                  </a>
                  <ul class="dropdown-menu animated fadeInUp pull-right" role="menu">
                    <li><a onClick={() => setOpenCustomize(true)}><i class="fal fa-edit"></i>Advanced settings</a></li>
                  </ul>

                  <Modal open={openCustomize} onClose={() => setOpenCustomize(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
                    <Customize closeModal={() => setOpenCustomize(false)}/>
                  </Modal>
                </div> */}
              </div>
            </div>

            <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
              <h4 className="color-black-always modal-title">Add task form</h4>
              <p className="modal-description">Task forms are questions your client will be asked when they submit new task requests.</p>
              <div className="row no-margin">
                <TaskForms refetchData={fetchServiceForms} closeModal={() => setOpen(false)}/>
              </div>
            </Modal>
          </div>

          <div className="row mt-15">
              <React.Fragment>
                {serviceForms.length > 0 ?(
                  <div className="row ml-15-negative">
                    {service_forms_list}
                  </div>
                ):(
                  <React.Fragment>
                    <div className="daas-task-forms-empty">
                      <lottie-player class="information-lottie" autoplay loop mode="normal" src="https://assets5.lottiefiles.com/packages/lf20_ljx86sv6.json"></lottie-player>
                      Add custom questions for new task requests

                      <VideoGuide src="https://www.loom.com/embed/8bd2b7c197044908ba9697f1ded85c36?sid=1e7c1ed8-28f0-4e8a-9c58-8223214edaf5" />
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
          </div>
        </div>
      </React.Fragment>
    ) : null
    )
}

export default Index