import React, { useState, useEffect, useContext } from "react";
import { NavLink, useRouteMatch, useHistory } from "react-router-dom";
import { UserContext } from "../Shared/UserContext";
import { Modal } from "react-responsive-modal";
import Edit from "../Users/Edit.js";
import axios from "axios"; 
import Premium from "../Subscriptions/Premium.js";
import NotificationSettings from "../Notifications/NotificationSettings.js"
import NewOrganization from "../Organizations/New.js";
import EditTheme from "../Organizations/EditTheme.js";

const Organizations = ({organizationAuthorizations}) => {
  const match = useRouteMatch();
  const history = useHistory();
  const [organizations, setOrganizations] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const currentUser = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [openTheme, setOpenTheme] = useState(false);
  const [openSubscriptionModal, SetOpenSubscriptionModal] = useState(false);
  const [openNotificationSettings, setOpenNotificationSettings] = useState(false);
  const [electron, setElectron] = useState(window && window.process && window.process.type === 'renderer');

  useEffect(() => {
    fetchOrganizations();
  }, [currentUser]);

  function fetchOrganizations() {
    $.ajax({
      url: `/api/organizations.json`,
      type: "GET",
      dataType: "json",
      success: (response) => {
        setOrganizations(response.organizations);
        // setTotalUnread(response.organizations.filter(organization => organization.organization.token != match.params.organization_id).reduce((accumulator, object) => {
        //   return accumulator + object.organization.unread_notifications_size;
        // }, 0))
        if(typeof fetchProfile === "function") {
          fetchProfile(); // from portfolios/index.js
        } 
        setLoaded(true);
      },
      error: (response) => {
        // window.location.href = `/not-found`
      },
    });
  }

  const deleteOrganizationUser = () => {
    if(confirm("Are you sure?")){
      axios.post(`/api/o/${match.params.organization_id}/organization_users/delete`)
      .then(function(response){
        // history.push(response.redirect_url)
        window.location.href = "/"
      })
    }
  }

  const organizations_list = organizations.map((organization) => {
    return (
      <React.Fragment key={organization.organization.token}>
        <li>
          <a href={`https://${organization.organization.subdomain}.${window.location.hostname.includes("ngrok") ? "queue.ngrok.io" : "usequeue.com"}?token=${currentUser.token}`} className={`navigation-organizations-item ${match.params.organization_id == organization.organization.token && "font-weight-600"}`}>
            {organization.organization.title}
          </a>
        </li>
      </React.Fragment>
    );
  });

  return (
    <React.Fragment>
      {currentUser != null && organizations != null && (
        <React.Fragment>
          <div class="btn-group daas-platform-links-li">
            <a type="button" class="daas-platform-links-a sidebar-text-color dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img className="organization-title-navigation-avatar" src={organizationAuthorizations.organization.avatar}/> 
              <div className="organization-title-navigation font-weight-600 sidebar-text-color">
                {organizationAuthorizations.organization.title}
                <i class="fal opacity-6 fa-cog navigation-organizations-dropdown-carat"></i>
              </div>
            </a>

            <ul class="navigation-organizations-ul dropdown-menu animated fadeInUp">
              <li>
                <a onClick={() => setOpen(true)}>
                  <i className="fal fa-user-cog"></i>Account settings
                </a>
                <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
                  <Edit/>
                </Modal>
              </li>

              <li>
                <a onClick={() => setOpenTheme(true)}>
                  <i className="fal fa-lightbulb-on"></i>Edit Theme
                </a>
                <Modal open={openTheme} onClose={() => setOpenTheme(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
                  <EditTheme organization={organizationAuthorizations.organization} refetchData={fetchOrganizations}/>
                </Modal>
              </li>
              

              <li>
                <a href="https://roadmap.usequeue.com" target="blank"><i class="fal fa-fire"></i>Public Roadmap</a>
              </li>

              <li>
                <a href="https://desktop.usequeue.com" target="blank"><i class="fal fa-desktop"></i>Download desktop app</a>
              </li>

              {organizationAuthorizations.organization.organization_user && 
                <React.Fragment>
                  <li className="navbar-dropdown-label">{organizationAuthorizations.organization.title}</li>
                  <li>
                    <a onClick={() => SetOpenSubscriptionModal(true)}><i class="fal fa-rocket"></i>Subscription</a>
                    <Modal open={openSubscriptionModal} onClose={() => SetOpenSubscriptionModal(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
                      <Premium organization_id={match.params.organization_id}/>
                    </Modal>
                  </li>

                  {/* <li>
                    <EditOrganization organization={organizationAuthorizations.organization} fetchOrganizations={fetchOrganizations}/>
                  </li> */}

                  {/* <li>
                    <a onClick={() => setOpenPeople(true)}>
                      <i class="fal fa-users"></i>People
                    </a>

                    <Modal open={openPeople} onClose={() => setOpenPeople(false)} className="modal-body-white" classNames={{modal: 'width-1000-modal'}} center>
                      <People />
                    </Modal>
                  </li> */}

                  {/* <li>
                    <a onClick={() => setOpenPayments(true)}>
                      <i class="fal fa-usd-circle"></i>Payments
                    </a>
                    <Modal open={openPayments} onClose={() => setOpenPayments(false)} className="modal-body-white" classNames={{modal: 'width-1000-modal'}} center>
                      <Payments />
                    </Modal>
                  </li> */}
                </React.Fragment>
              }

              <li>
                <a id="signOut" href={`/signout?electron=${electron}`} className="mixpanelTrack" data-mixpaneltrack="Navigation - Signout"><i className="fal fa-sign-out-alt"></i>Log out</a>
              </li>


                <React.Fragment>
                  {
                    ((!organizationAuthorizations.organization.owner) && (organizationAuthorizations.organization.organization_user)) &&
                    <li>
                      <a onClick={deleteOrganizationUser}>
                        <i className="fas fa-house-leave"></i>Leave workspace
                      </a>
                    </li>
                  }
                  <li role="separator" class="divider"></li>
                  <li className="navbar-dropdown-label">All workspaces</li>
                  {organizations_list}

                  <li>
                    <NewOrganization refetchData={fetchOrganizations} />
                  </li>
                </React.Fragment>

            </ul>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>

  );
};

export default Organizations;
