import React , { useState, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import Toggle from 'react-toggle';
import {OrganizationContext} from "../../Shared/OrganizationContext.js";

const Customize = ({closeModal}) => {
  const organizationAuthorizations = useContext(OrganizationContext);
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [disableDescription, setDisableDescription] = useState(!organizationAuthorizations.organization.disable_description);
  const [disablePriority, setDisablePriority] = useState(!organizationAuthorizations.organization.disable_priority);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.put(`/api/o/${match.params.organization_id}`, {
      disable_description: !disableDescription,
      disable_priority: !disablePriority,
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Update successful");
        fetchOrganizationAuthorizations(); // located in index.js
        closeModal();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <div className="row align-items-center animated fadeIn">
          <div className="col-12 col-md-8">
            <h5 className="font-weight-600"><i class="fas fa-grip-lines mr-5"></i>Description</h5>
            <p>Disable or enable the description from showing on the task form. Will still be visible on the task form for updating. </p>
          </div>

          <div className="col-12 col-md-4 text-right">
            <Toggle checked={disableDescription} onChange={() => setDisableDescription(!disableDescription)} icons={false}/>
          </div>
        </div>

        <div className="row align-items-center mt-30 border-top mt-15 pt-15 animated fadeIn">
          <div className="col-12 col-md-8">
            <h5 className="font-weight-600"><i class="fas fa-exclamation-square mr-5"></i>Priority</h5>
            <p>
              Disable priority from appearing on the task form. Will be available on the task form for updating.
            </p>
          </div>

          <div className="col-12 col-md-4 text-right">
            <Toggle checked={disablePriority} onChange={() => setDisablePriority(!disablePriority)} icons={false}/>
          </div>
        </div>

        <div className="field text-right pt-15 border-top mt-15">
          <button type="submit" className="btn btn-primary">Submit</button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default Customize;