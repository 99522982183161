import React from "react";
import ReactTooltip from 'react-tooltip';


const User = (props) => {
  return(
    <React.Fragment>
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
      <div data-tip={props.user.username} className="users-all-item" style={{width: "30px"}}>
        <img src={props.user.avatar} className="client-users-item-image"/>
      </div>
    </React.Fragment>
  )
}


export default User; 