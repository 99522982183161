import React , { useState, useContext } from 'react';
import { useHistory, useRouteMatch, Link } from 'react-router-dom'
import { Draggable } from "react-beautiful-dnd";
import { Modal } from 'react-responsive-modal';
import FullCard from "./FullCard.js";
import TaskDropdown from "./TaskForms/TaskDropdown.js";
import ReactTooltip from 'react-tooltip';
import { AppContext } from "../../../Shared/AppContext.js";
import axios from "axios"; 
import { useTranslation } from 'react-i18next';
import {useContextMenu} from "react-contexify";
import NewLabel from "../../../Labels/NewLabel.js";
import NewTaskTemplate from "../../../TaskTemplates/NewTaskTemplate.js";
import Cover from "./Task/Cover.js";

const Card = ({card, index, refetchData, organizationUser, column, openTask, setOpenTask, projectAuthorizations, project_id }) => {
  const {markNotificationRead} = useContext(AppContext);
  const history = useHistory();
  const match = useRouteMatch();
  const [open, setOpen] = useState(false);
  // const [showSubtasks, setShowSubtasks] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [openNewLabel, setOpenNewLabel] = useState(false);
  const [openTaskTemplate, setOpenTaskTemplate] = useState(false);
  const isPortalUrl = location.pathname.includes('/portal/');
  const [openCover, setOpenCover] = useState(false);

  const { t } = useTranslation();
  const { show } = useContextMenu({
    id: card.token
  });
  function displayMenu(e){
    e.stopPropagation();
    // put whatever custom logic you need
    // you can even decide to not display the Menu
    show({
      event: e,
    });
  }

  const users_list = card.users.map(user => {
    return(
      <img src={user.avatar} className="kanban-card-users-image"/>
    )
  }); 

  // const subtasks_list = card.subtasks.sort((a, b) => a.position - b.position).map((subtask, index) => (
  //   <Card key={subtask.token} card={subtask} index={index} refetchData={refetchData} organizationUser={organizationUser} column={column} columns={columns} draggable_id={subtask.parent_task_id} projectAuthorizations={projectAuthorizations} project_id={project_id} users={users} setOpenTask={setOpenTask} setOpenNewLabel={setOpenNewLabel}/>
  // ));

  const markFinished = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDisabled(true);

    axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${card.token}`, {
      finished: !card.finished
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const card_task_labels_list = card?.task_labels?.map(task_label => {
    return(
      <span className={`badge border-all mr-10 mb-5`}>
        <span className="badge-label-color" style={{background: `#${task_label.label.color}`}}></span>
        {task_label.label.title}
      </span>
    )
  })

  return(
    card != null && 
    <React.Fragment>
      <Modal open={openNewLabel} onClose={() => setOpenNewLabel(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <NewLabel closeModal={() => setOpenNewLabel(false)}/>
      </Modal>
      
      <Modal open={openTaskTemplate} onClose={() => setOpenTaskTemplate(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <NewTaskTemplate task={card} refetchData={refetchData} project_id={project_id} closeModal={() => setOpenTaskTemplate(false)}/>
      </Modal>

      <Modal open={openCover} onClose={() => setOpenCover(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
        <Cover task_id={card.token} refetchData={() => {refetchData();}} closeModal={() => setOpenCover(false)} cover_exists={card.cover_url != null || card.unsplash_data != null}/>
      </Modal>

      <Draggable key={String(card.id)} draggableId={String(card.id)} index={index} isDragDisabled={
        isPortalUrl
          ? projectAuthorizations.disable_dragging
          : projectAuthorizations.disable_dragging && !projectAuthorizations.organization_user
      }>
        {(provided, snapshot) => (
          <div onContextMenu={displayMenu} onClick={() => {card.unread && markNotificationRead("Task", card._id)}} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <div className={`kanban-card default-padding background-3 background-hover hover-parent-opacity ${card.unread ? "border-red" : ""}`} onClick={() => {setOpen(true); history.push(`?task_id=${card.token}`);}}>
              {card.unsplash_data && 
                <div className="kanban-card-cover">
                  <img src={card.unsplash_data.urls.small} className="kanban-card-cover-image"/>
                </div>
              }
              {card.cover_url && 
                <div className="kanban-card-cover">
                  <img src={card.cover_url} className="kanban-card-cover-image"/>
                </div>
              }

              {card.project != null && 
                <Link to={`/o/${match.params.organization_id}/projects/${card.project.token}/tasks`} className="badge background-active color-1 grow mb-10" onClick={(e) => e.stopPropagation()}>
                  <img src={card.project.avatar} className="project-list-avatar" style={{width: 15, height: 15}}/>
                {card.project.title}</Link>
              }
              {card.priority != "" && 
                <div data-tip={card.priority} className={`kanban-card-info-status ${card.priority == "High" && "background-red"} ${card.priority == "Medium" && "background-orange"} ${card.priority == "Low" && "background-blue"}`}></div>
              }
              
              <h4 className="kanban-card-title">
                <div className="kanban-card-title-wrap">
                  {card.private && 
                    <i className="fal fa-lock mr-5"></i>
                  }
                  {card.title}
                </div>
                <TaskDropdown task={card} refetchData={refetchData} column={column} project_id={project_id} organizationUser={organizationUser} displayMenu={displayMenu} setOpenNewLabel={setOpenNewLabel} setOpenTaskTemplate={setOpenTaskTemplate} setOpenCover={setOpenCover}/>
              </h4>

              <div className="kanban-card-info hover-parent-opacity">
                <div className="kanban-card-info-comments">
                  {(card.subtasks && card.subtasks.length > 0 ) &&
                    <>
                      <span data-tip="Subtasks" className={`badge ${card.subtasks.every(subtask => subtask.finished) ? "badge-green" : "background-active"} hover-scale cursor-default mr-5 mb-5 font-weight-500 task-deadline-wrapper`}>
                        {card.subtasks.every(subtask => subtask.finished) ? (
                          <>
                            <i class="far fa-check-square mr-5"></i>
                          </>
                        ):(
                          <>
                            <i class="far fa-square mr-5"></i>
                          </>
                        )}
                        {card.subtasks.filter(subtask => subtask.finished).length}/{card.subtasks.length}
                      </span>
                    </>
                  }

                  {card.deadline != null &&
                    <span onClick={markFinished} data-tip="Due date" className={`badge ${card.finished ? "badge-green" : card.deadline_color} cursor-default mr-5 mb-5 font-weight-500 task-deadline-wrapper`}>
                      {disabled ? (
                        <>
                          <i className="fas fa-spinner fa-spin mr-5"></i>
                        </>
                      ):(
                        <>
                          {card.finished ? (
                            <>
                              <i class="far fa-check-square mr-5 task-deadline-clock"></i>
                              <i class="far fa-square mr-5 task-deadline-check" data-tip="Mark unfinished"></i>
                            </>
                          ):(
                            <>
                              <i class="far fa-clock mr-5 task-deadline-clock"></i>
                              <i class="far fa-square mr-5 task-deadline-check" data-tip="Mark finished"></i>
                            </>
                          )}
                        </>
                      )}
                      {card.deadline_short}
                    </span>
                  }


                  {/* {card.parent_task_id != null && <span className="badge background-active ml-10">{card.column.title}</span>} */}

                  {card_task_labels_list}
                </div>
                <div className="kanban-card-users font-12">
                  {users_list}
                </div>
              </div>
            </div>
          
            {/* {showSubtasks && 
              <div className="animated fadeIn" style={{marginLeft: "20px", paddingLeft: "5px", borderLeft: "1px solid #c5c7ca"}}>
                {subtasks_list}
              </div>
            } */}
          </div>
        )}
      </Draggable>
      <Modal focusTrapped={false} closeOnEsc={false} open={open || openTask} onClose={() => {setOpen(false); history.replace(location.pathname); setOpenTask(false)}} className="modal-body-white" classNames={{modal: 'modal-side'}} center>
        <FullCard task_id={card.token} refetchData={refetchData} project_id={project_id}/>
      </Modal>
    </React.Fragment>
  )
}

export default Card